
<div *ngIf="!isLoggIn">
    <div class="row">
        <div class="col-md-2" > </div>

  <div class="col-md-8">
        <h1>Registrarse</h1>
        <form [formGroup]="loginForm" (ngSubmit)="onlogin()" >
     
            <div class="uc-form-group" style="max-width: 360px">
            <label for="username">RUT o identificación</label>
                <input
                id='username'
                type='text' 
                formControlName="username"
                placeholder="11222333-4"
                class="uc-input-style"
                required
                autofocus
                data-cy='username'>
            </div>
            <mat-error *ngIf="isValidField('username')" data-cy='error_username'>
                {{ getErrorMessage('username')}}
            </mat-error>

            <div class="uc-form-group" style="max-width: 360px">
                <label for="password" >Contraseña</label>
                <input 
                id='password'
                type = 'password'
                formControlName="password"
                class="uc-input-style"
                placeholder="no es la misma UC"
                data-cy='password'
                >
                
            </div>

            <mat-error id="passError" *ngIf="isValidField('password')" data-cy='error_password'>
                {{ getErrorMessage('password')}}
            </mat-error>
            <div class="text-right">
            <button id='primaryAction' data-cy='primaryAction'
            type='submit' class="uc-btn btn-cta" >Ingresar</button>

</div>


{{referral}}

        </form>
<!--         <ul>
            <li>Estudiante <br><b>12345678-9</b>  : <b>123456789</b></li>
            <li>Coordinador:<br> <b>98765432-1</b>: <b>987654321</b></li>
        </ul> -->
<p> <a href="https://www.faif.cl/2019/index.php/auth/forgot_password" target="_blank" data-cy='recordar_contraseña'>¿No recuerdas tu contraseña?</a></p>
</div>  
<div class="col-md-2" > </div>  
</div>

