import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedGuard } from './guards/logged.guard';

const routes: Routes = [
  //{ path: '',  redirectTo: '/auth', pathMatch: 'full' },
  { path: 'auth' , loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule) },
  
  { path: '', canLoad :[LoggedGuard], loadChildren: () => import('@app/pages/home/home.module').then(m => m.HomeModule) },
  { path: '401', loadChildren: () => import('./pages/errors/not-authorized/not-authorized.module').then(m => m.NotAuthorizedModule) },
  { path: '403', loadChildren: () => import('./pages/errors/not-authorized/not-authorized.module').then(m => m.NotAuthorizedModule) },
  { path: 'logout', loadChildren: () => import('./pages/auth/logout/logout.module').then(m => m.LogoutModule) },
  { path: 'forgot', loadChildren: () => import('./pages/auth/forgot/forgot.module').then(m => m.ForgotModule) },
  { path: '**', loadChildren: () => import('./pages/errors/not-found/not-found.module').then(m => m.NotFoundModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
