import { Injectable } from '@angular/core';
import { environment } from '@env/environment'
import { UserResponse, User} from '@app/interfaces/user.interface';

import { Router} from '@angular/router'
import { HttpClient } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { JwtHelperService } from "@auth0/angular-jwt";


const helper = new JwtHelperService();
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false) 

  constructor(private http:HttpClient, private router:Router) {
    this.checkToken()
  }

  get isLogged(): Observable<boolean>{
    return this.loggedIn.asObservable();
  }

  login(authData:User):Observable<UserResponse | void>{
    return this.http.post<UserResponse>(`${environment.LOGIN_URL}/Auth/login`,authData).pipe(
      map((res:UserResponse)=>{
      if(res?.token){
      this.saveStorage(res.token)
      this.loggedIn.next(true)
      var user:any = this.getUserData();
        localStorage.setItem('username', user.name)
      return res
      }
      return false
      
      }),
      catchError((err)=>{
        console.log(err)
      this.router.navigate(['/401'])
      return []
      }
      
      )
    )

  }


  logOut():void{
    localStorage.removeItem('token');
    localStorage.clear();
    this.loggedIn.next(false)
    this.router.navigate(['/auth'])
    
  }

  isInGroup(group:string):boolean{
    const roles = this.getRol();
    
      if(!roles) return false;
      if(roles.find((x:any) => x.title===group)){
        return true
      }else{
        return false
      }    
  }
  
  private checkToken(){  
    //const token =  localStorage.getItem('token'); 
    const token =  this.getToken()
    if(token){
      if( token.split(".").length<3 ){
        this.logOut();
      }else{
      const isExpired = helper.isTokenExpired(token)
        if(!isExpired){
          this.loggedIn.next(true);
          
        }else{
          this.loggedIn.next(false);
          this.logOut();
        }  
      }
    }else{
      this.logOut();
    }
  }


  public getRol():any | void{

    const token =  this.getToken()

    if(token){
      const decodedToken = helper.decodeToken(token);
      //console.log(JSON.parse(decodedToken.data.roles))
      return decodedToken.data.roles
    }

  }
  public getUser():number | void{
 
    const token =  this.getToken()

    if(token){
      const decodedToken = helper.decodeToken(token);
      return Math.floor(decodedToken.data.userId)
    }else{
      this.logOut()
    }

  }

  public getUserData():any{
 
    const token =  this.getToken()
    if(token){
      const decodedToken = helper.decodeToken(token);
        return decodedToken.data
    }
  }


  public setUser(user_id):any{

    return this.http.get(`${environment.API_URL}/Auth/token/`,user_id).pipe(
      map(data=>{data[0]
        console.log('data', data)
      })
    )
  }


  getToken():string | null{
    return localStorage.getItem('token')
  }

  private saveStorage(token:string):void{
    localStorage.setItem('token',token)
    this.loggedIn.next(true)
  }

  private handleError(err:any):Observable<never>{
    let errorMessage =  "Un error ha ocurrido al obtener datos";
    if(err){
      errorMessage= `Error: code ${err.message}`
    }
    window.alert(errorMessage)
    //console.log(err)
    return throwError(errorMessage)
    
  }

}
