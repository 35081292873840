
    <header class="uc-header">
        <div id="uc-global-topbar"></div>

          <!-- Menú para versión Escritorio -->
          <div class="container d-none d-lg-block">
            <div class="row align-items-center">
              <div class="col-lg-3 col-xl-2">
                <img src="https://kit-digital-uc-desa.s3.amazonaws.com/img/logo-uc-azul.svg" alt="Pontificia Universidad Católica de Chile" class="img-fluid">
              </div>
              <div class="col-lg-8 col-xl-9 pl-60">
                <div class="h2 text-font--serif text-color--blue mt-24">{{NOMBRE_FACULTAD}}</div>
                <div class="text-color--gray p-size--lg">{{INTRO_TEXT}}</div>
              </div>
            </div>
</div>
      
        </header>   
    <div class="container">

          <router-outlet></router-outlet>




        
      <!-- contenido aquí -->
    </div>
    <!-- Footer global -->
    <div id="uc-global-footer"></div>



