import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '@app/services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

/**
 * Componenente que se encarga de hacer el login de los usuarios
 */
export class LoginComponent implements OnInit {
  hide = true;

  private isValidRut =
    /([0-9]{7,8}-[0-9Kk]{1}| [0-9]{1,2}.[0-9]{3}.[0-9]{3}-[0-9Kk]{1})/;
  private subscription: Subscription = new Subscription();

  loginForm: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private router: Router
  ) {
    this.loginForm = this.fb.group({
      username: [
        '',
        [Validators.required, Validators.pattern(this.isValidRut)],
      ],
      password: ['', [Validators.required, Validators.minLength(5)]],
    });
  }
  isLoggIn:any;
  token:any;


  /**
   *  Inicializa todo verifica la existencia de un token y envia a login en caso contrario.
   * 
   */

   referral:string
  ngOnInit(): void {
this.referral = document.referrer;

    this.subscription.add(
      this.authService.isLogged.subscribe((x) => {
        this.isLoggIn = x;
      })
    );
    var roles = this.authService.getRol();
    if (!roles) {
      this.router.navigate(['/auth']);
    } else {
      this.router.navigate(['']);
      
    }
  }

  /**
   *  destruye la subscripcion
   * 
   */

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * 
   * @returns Luego de logearse enviará al dashboard de usuario
   */

  onlogin(): void {
    if (this.loginForm.invalid) {
      return;
    }
    const formValue = this.loginForm.value;
    this.subscription.add(
      this.authService.login(formValue).subscribe((res) => {
        if (res && res?.status != false) {
          var roles = this.authService.getRol();
          
          if (!roles){
            this.router.navigate(['/auth'])
          }else{
            this.router.navigate([''])
          }
      }else{
          this.router.navigate([''])
      }
    })
    )
  }

  /**
   * Obtiene el mensaje de error correspondiente al tipo de error en un campo
   * @param field nombre del campo a validar
   * @returns 
   */
  getErrorMessage(field: string) {
    let message: string;

    if (this.loginForm.get(field).errors.required) {
      message = ' Debes ingresar un valor';
    } else if (this.loginForm.get(field).errors?.pattern) {
      message = ' Debes ingresar un valor con el patron valido';
    } else if (this.loginForm.get(field).errors?.minlength) {
      const minLength =
        this.loginForm.get(field).errors?.minlength.requiredLength;
      message = `Debe ser mas largo  que ${minLength}`;
    }

    return message;
  }

  /**
   *  Verifica si el campo del formulario es valido
   * @param field nombre del campo
   * @returns 
   */
  isValidField(field: string): boolean | void {
    return (
      (this.loginForm.get(field).touched || this.loginForm.get(field).dirty) &&
      !this.loginForm.get(field).valid
    );
  }
}
