import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'login';
  NOMBRE_FACULTAD = "Facultad de Agronomía e Ingeniería Forestal";
  INTRO_TEXT = "Nullam fringilla ipsum ac nunc consectetur, a dictum tortor sagittis. Nam sed augue posuere odio dictum tempor a et mi. Quisque eu sem finibus, interdum orci ac, interdum eros."
}
